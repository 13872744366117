import {EnvironmentConfig} from "../../../config/EnvironmentConfig";
import { TGenerateAiResponseFn, TGenerateAiResponseProps} from "../ai.prompts";

export type AppsProdSearchResult = {
    index: string;
    title: string;
    target: string;
};

export const getPromptForAppsProd: TGenerateAiResponseFn<AppsProdSearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<AppsProdSearchResult>) => {
        let target = searchResult.target;
        if (target.startsWith('/')) {
            target = EnvironmentConfig.gatewayURL + target;
        }
        return ` ElasticSearch Results:
              Title: ${searchResult["title"]}
              Url: ${target}
              
              CONTEXT: You're company employee support agent. User asked a question that 
              went to the ElasticSearch and found matched title of the application that he is 
              looking for. Your job is to tell user the app name and its url that he is looking for. \\n\\n
  
              INSTRUCTIONS:\\n
              1) See the title of the application.\\n
              2) Generate short response based on the matched title and user's question.\\n
              2a) If title includes URL, keep URL in the response.\\n
              4) Ensure the response is in natural language, accurate and concise.\\n
              
              Last step- Respond in language: ${language}`;
    };